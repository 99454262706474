import { ENV, TENANT } from "config/api";
import firebase from "firebase/app";
import "firebase/auth";
import authKey from "../../api_key.json"
console.log(TENANT)
const TENANT_ENV = `${TENANT.includes("localhost") ? "localhost" : TENANT}.${ENV ? ENV : "devs"}`

const app = firebase.initializeApp({
  apiKey: authKey[TENANT_ENV].apiKey,
  authDomain: authKey[TENANT_ENV].authDomain,
});
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

export const auth = app.auth();
export default app;
export const firebaseobj = firebase;
